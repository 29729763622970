.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  height: 11vh;
  margin-bottom: -3vh;
  margin-top: 2vh;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  overflow: visible !important;
}

ul {
  list-style-type: none;
}

.small-text {
  font-size: small;
  text-align: justify;
}
.sup {
  position: relative;
  bottom: 1ex;
  font-size: 80%;
}

.row-link {
  text-decoration: none;
  color: black;
}

.table {
  margin: 1vh;
  max-width: 100%;
  overflow: auto;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 0.3em !important;
  min-width: 100%;
}

tbody,
thead {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  text-align: left;
}

tr {
  height: 30px !important;
  background-color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4) !important;
}

td,
th {
  min-width: 20%;
  max-width: 30%;
}

.nav-link {
  width: 100%;
}
.nav-link {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.nav-bar {
  display: flex;
  background-color: white;
  justify-content: center;
}

.nav-link-eign {
  margin: 0 auto;
  padding: 1vh;
  width: 50%;
  background-color: white;
  border: 0px;
  font-size: min(1.13vh + 1vw, 23px);
  text-align: center;
  border-bottom: 1px solid #ededed;
}

.eign-toggled {
  color: #144a65;
  outline: none;
  background-color: #ffffff;
  font-weight: bold;
}

.Buttons-choice {
  display: flex;
  justify-content: space-between;
  margin: 1vh 0 -2vh 0;
}

.Button-choice {
  height: 6vh;

  border: 1px solid #144a65;
  font-weight: bold;
  font-size: 12px;
  color: #144a65;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff;
  outline: none;
  width: 30%;
  height: 5vh;
}

.toggled {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #144a65;
}
.banner-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Banner-title {
  color: #2e4b65;
  font-size: 1.5rem;
}

.eign-more {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.eign-price {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2vh;
}

.eign-distance {
  display: flex;
  flex-direction: column;
  margin: 2vh;
  width: 100%;
  /* max-width: 768px; */
}

.eign-price-m {
  margin: 0px;
  height: 27px;
  width: 128px;
  color: #97a1ae;

  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
}

.eign-price-title {
  color: #2e4b65;

  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin: 0px;
}

.eign-price-range {
  margin: 0px;
}

.eign-price-price {
  color: #2e4b65;

  font-size: 32px;
  line-height: 48px;
  margin: 0px;
}

.App-header {
  background-color: #ffffff;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: rgb(191, 191, 191);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  padding-top: 4vh;
  justify-content: flex-start;
  color: #212529;
}

main {
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}

.App-link {
  color: #61dafb;
}

.App-banner {
  margin: 2vh 0;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.Address {
  width: 100%;
  color: #2e4b65;

  font-weight: 300;
  font-size: auto;
  line-height: 2rem;
}

.date {
  color: #000000;

  font-size: 0.8rem;
  margin: 1vh 0;
  text-align: end;
}

.ignore {
  margin: 2vh;
}

.both-charts {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-evenly;
  padding: 1vh;
}

.extra-pad {
  padding: 1vh;
}

.both-charts-text {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.distance-pics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1vh;
}

.distance-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.distance-pics-single {
  display: flex;
  flex-direction: row;
  max-height: 64px;
  width: 50%;
}

.invis {
  display: none;
}

#invicible0:empty ~ #distance-0,
#invicible1:empty ~ #distance-1,
#invicible2:empty ~ #distance-2,
#invicible3:empty ~ #distance-3 {
  display: none;
}

.distance-time {
  margin: 0;
}

.distance-pic {
  width: auto;
  max-width: 32px;
  height: auto;
  max-height: 32px;
  margin: 0 1vh;
}

.distance-title {
  margin: 0 auto;
}

.distance-pics-single {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 auto;
}

.distance-pics {
  display: flex;
  flex-direction: column;
}

.right {
  text-align: right;
}

.eign-list {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: larger;
  padding-inline-start: 0;
}
.category {
  font-weight: bold;
}

input {
  display: none;
} /* hide radio buttons */
input + label {
  display: inline-block;
  background: #eee;
  padding: 4px 12px;
  border-radius: 4px 4px 0 0;
  position: relative;
  top: 1px;
} /* show labels in line */
input ~ .tab,
input ~ .chartTab {
  display: none;
  padding: 12px;
}

input ~ .distance-content {
  display: none;
  padding: 0.75rem 2rem;
}

/* show contents only for selected tab */
#tab1:checked ~ .tab.content1,
#tab2:checked ~ .tab.content2,
#chartTab1:checked ~ .chartTab.content1,
#chartTab2:checked ~ .chartTab.content2,
#driving_dist:checked ~ .distance-content.driving_content,
#walking_dist:checked ~ .distance-content.walking_content,
#bicycling_dist:checked ~ .distance-content.bicycling_content {
  display: block;
}

input:checked + label,
#tab1:checked ~ .banner-navbar > #eign_label,
#tab2:checked ~ .banner-navbar > #um_label,
#chartTab1:checked ~ .charts-navbar > #tegundir_label,
#chartTab2:checked ~ .charts-navbar > #aldur_label,
#driving_dist:checked ~ .distance-navbar > #driving_label,
#walking_dist:checked ~ .distance-navbar > #walking_label,
#bicycling_dist:checked ~ .distance-navbar > #bicycling_label {
  /* white background for selected tab */
  background: #fff;
  border-bottom: 1px solid #e0a233;
  font-weight: bolder;
}

.distance-navbar,
.banner-navbar,
.charts-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.desktop {
  display: none;
}
.mobile {
  display: block;
}

.recharts-pie-label-text {
  font-size: 12px;
}

.dist-nav {
  max-width: 25%;
  margin: 0;
}


@media only screen and (min-width: 768px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
  .eign-price {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
  }
  .eign-more {
    width: 50%;
  }
  .even {
    display: flex;
    flex-direction: row;
  }
  .right {
    text-align: left;
  }
  .Button-choice {
    height: 3vh;
    font-size: 20px;
  }

  .Banner-title {
    height: 2rem;
    color: #2e4b65;

    font-size: 1.5rem;
  }
  .Banner-title-big {
    font-weight: bolder;
    padding: 20px;
    font-size: 1.5rem;
    /* color: #2e4b65; */
    line-height: 2rem;
    height: 2rem;
    width: 50%;
    text-align: center;
    margin: 0;
    border-bottom: 1px solid #e0a233;
  }
  /* .Address {
    height: 56px;
    font-size: 40px;
    line-height: 60px;
  } */
  /* .distance-pics {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  } */


  .distance-part {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin: 0 auto;
  }
  .distance-pic {
    width: auto;/* 
    max-width: 3rem; */
    height: auto;
    max-height: 3rem;
  }
  .distance-pics-single {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: auto;
  }

  .distance-pics-single:nth-child(even) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .eign-distance {
    margin: 4vh;
    width: 100%;
  }

  #driving_dist:checked ~ .distance-content.driving_content,
  #walking_dist:checked ~ .distance-content.walking_content,
  #bicycling_dist:checked ~ .distance-content.bicycling_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  main {
    max-width: 1140px;
  }
  .distance-pic {
    width: auto;
    max-width: 4rem;
    height: auto;
    max-height: 4rem;
  }

  .eign-distance {
    width: 100%;
    max-width: 100%;
  }
}
