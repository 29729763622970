#page {
  align-items: center;
  background: #2f4c65;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 1s;
}

#phrase_box {
  display: flex;
  flex-flow: column;
  height: 150px;
  overflow: hidden;
  width: 260px;
}

#phrases {
  -webkit-animation: slide-phrases-upward 20s;
  animation: slide-phrases-upward 20s;
}

#footer {
  bottom: 30px;
  color: white;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
}

@-webkit-keyframes slide-phrases-upward {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  5% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  10% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  15% {
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
  }
  20% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
  }
  25% {
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
  }
  30% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
  }
  35% {
    -webkit-transform: translateY(-350px);
    transform: translateY(-350px);
  }
  40% {
    -webkit-transform: translateY(-400px);
    transform: translateY(-400px);
  }
  45% {
    -webkit-transform: translateY(-450px);
    transform: translateY(-450px);
  }
  50% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  55% {
    -webkit-transform: translateY(-550px);
    transform: translateY(-550px);
  }
  60% {
    -webkit-transform: translateY(-600px);
    transform: translateY(-600px);
  }
  65% {
    -webkit-transform: translateY(-650px);
    transform: translateY(-650px);
  }
  70% {
    -webkit-transform: translateY(-700px);
    transform: translateY(-700px);
  }
  75% {
    -webkit-transform: translateY(-750px);
    transform: translateY(-750px);
  }
  80% {
    -webkit-transform: translateY(-800px);
    transform: translateY(-800px);
  }
  85% {
    -webkit-transform: translateY(-850px);
    transform: translateY(-850px);
  }
  90% {
    -webkit-transform: translateY(-900px);
    transform: translateY(-900px);
  }
  95% {
    -webkit-transform: translateY(-950px);
    transform: translateY(-950px);
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
  }
}

@keyframes slide-phrases-upward {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  5% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  10% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  15% {
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
  }
  20% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
  }
  25% {
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
  }
  30% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
  }
  35% {
    -webkit-transform: translateY(-350px);
    transform: translateY(-350px);
  }
  40% {
    -webkit-transform: translateY(-400px);
    transform: translateY(-400px);
  }
  45% {
    -webkit-transform: translateY(-450px);
    transform: translateY(-450px);
  }
  50% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  }
  55% {
    -webkit-transform: translateY(-550px);
    transform: translateY(-550px);
  }
  60% {
    -webkit-transform: translateY(-600px);
    transform: translateY(-600px);
  }
  65% {
    -webkit-transform: translateY(-650px);
    transform: translateY(-650px);
  }
  70% {
    -webkit-transform: translateY(-700px);
    transform: translateY(-700px);
  }
  75% {
    -webkit-transform: translateY(-750px);
    transform: translateY(-750px);
  }
  80% {
    -webkit-transform: translateY(-800px);
    transform: translateY(-800px);
  }
  85% {
    -webkit-transform: translateY(-850px);
    transform: translateY(-850px);
  }
  90% {
    -webkit-transform: translateY(-900px);
    transform: translateY(-900px);
  }
  95% {
    -webkit-transform: translateY(-950px);
    transform: translateY(-950px);
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
  }
}
#loadingCheckCircleSVG-0 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-1 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-2 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-3 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-4 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-5 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-6 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-7 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-8 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 6.5s;
  animation-delay: 6.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-9 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 7.5s;
  animation-delay: 7.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-10 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 8.5s;
  animation-delay: 8.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-11 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-12 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 10.5s;
  animation-delay: 10.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-13 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 11.5s;
  animation-delay: 11.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-14 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 12.5s;
  animation-delay: 12.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-15 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 13.5s;
  animation-delay: 13.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-16 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 14.5s;
  animation-delay: 14.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-17 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 15.5s;
  animation-delay: 15.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-18 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 16.5s;
  animation-delay: 16.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-19 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 17.5s;
  animation-delay: 17.5s;
  fill: white;
  opacity: 0;
}

#loadingCheckCircleSVG-20 {
  -webkit-animation: fill-to-white 5000ms;
  animation: fill-to-white 5000ms;
  -webkit-animation-delay: 18.5s;
  animation-delay: 18.5s;
  fill: white;
  opacity: 0;
}

@-webkit-keyframes fill-to-white {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fill-to-white {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#loadingCheckSVG-0 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

#loadingCheckSVG-1 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

#loadingCheckSVG-2 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#loadingCheckSVG-3 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

#loadingCheckSVG-4 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

#loadingCheckSVG-5 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

#loadingCheckSVG-6 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

#loadingCheckSVG-7 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s;
}

#loadingCheckSVG-8 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 6.5s;
  animation-delay: 6.5s;
}

#loadingCheckSVG-9 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 7.5s;
  animation-delay: 7.5s;
}

#loadingCheckSVG-10 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 8.5s;
  animation-delay: 8.5s;
}

#loadingCheckSVG-11 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}

#loadingCheckSVG-12 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 10.5s;
  animation-delay: 10.5s;
}

#loadingCheckSVG-13 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 11.5s;
  animation-delay: 11.5s;
}

#loadingCheckSVG-14 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 12.5s;
  animation-delay: 12.5s;
}

#loadingCheckSVG-15 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 13.5s;
  animation-delay: 13.5s;
}

#loadingCheckSVG-16 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 14.5s;
  animation-delay: 14.5s;
}

#loadingCheckSVG-17 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 15.5s;
  animation-delay: 15.5s;
}

#loadingCheckSVG-18 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 16.5s;
  animation-delay: 16.5s;
}

#loadingCheckSVG-19 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 17.5s;
  animation-delay: 17.5s;
}

#loadingCheckSVG-20 {
  -webkit-animation: fill-to-coral 5000ms;
  animation: fill-to-coral 5000ms;
  -webkit-animation-delay: 18.5s;
  animation-delay: 18.5s;
}

@-webkit-keyframes fill-to-coral {
  0% {
    fill: white;
  }
  10% {
    fill: #2f4c65;
  }
  100% {
    fill: #2f4c65;
  }
}

@keyframes fill-to-coral {
  0% {
    fill: white;
  }
  10% {
    fill: #2f4c65;
  }
  100% {
    fill: #2f4c65;
  }
}
